<template>
  <div class="palm1">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="maintitle">
          <img src="../../assets/img/palmmain1.png" @click="palm0" />
          <div @click="palm0">首页</div>
          <div class="active" @click="palm1">产品介绍</div>
          <div @click="palm2">功能介绍</div>
          <div @click="palm3">下载</div>
        </div>
      </el-header>
      <el-main>
        <div class="palmmain"></div>
        <div class="palmtal">
          <div class="talinfo">
            <div class="left">
              <img src="../../assets/img/palmtal2.png" />
              <div class="tal">
                掌上8圈APP是一款专为上网服务营业场所经营者提供实时查询经营状况的助手APP。
              </div>
              <div class="tel">
                提供场所经营数据统计、消息推送、手机提现、行业资讯等服务，满足管理者随时随地了解场所经营情况的需求。
              </div>
            </div>
            <img src="../../assets/img/palmtal1.png" />
          </div>
        </div>
        <!-- 产品亮点 -->
        <div class="liangdian">
          <div class="ldbor">
            <div class="ldtitle">产品亮点</div>
            <div class="ldinfo">
              <div class="val1">
                <img src="../../assets/img/palm1_1.png" />
                <div class="valtal">场所管理</div>
                <div class="valtel">
                  支持多场所、多股东模式<br />一个账号同时管理多个场所
                </div>
              </div>
              <div class="val2">
                <img src="../../assets/img/palm1_2.png" />
                <div class="valtal">经营数据统计</div>
                <div class="valtel">数据查询全面、数据对比直观</div>
              </div>
              <div class="val3">
                <img src="../../assets/img/palm1_3.png" />
                <div class="valtal">经营动态推送</div>
                <div class="valtel">
                  场所经营状况、敏感操作及时推送<br />随时掌握场所经营情况
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <div class="fotter">
          <div class="fotterdiv">
            <div class="yuedong">
              <img src="../../assets/img/palmfooter.png" />
              <div class="text1">
                © Copyright 2008 yedone.com all rights reserved.
                渝网文[2013]0887-006号
              </div>
              <div class="text2">
                经营许可证：渝B2-20190234&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="zhizhao">营业执照</span><br />渝ICP备09014244号-16
              </div>
              <div class="text3">
                <img src="../../assets/img/guohui.png" />
                <div class="text3tel" @click="beian">
                  渝公网安备 50010802002169号
                </div>
              </div>
            </div>
            <div class="bor">
              <div class="bor1"></div>
              <div class="bor2"></div>
            </div>
            <div class="contactUs">
              <div class="women">联系我们</div>
              <div class="phone">
                <div class="dianhua1">
                  <div>客服电话（24h）</div>
                  <div>400-680-8811</div>
                </div>
                <div class="dianhua2">
                  <div>商务电话（工作日09:00-18:00）</div>
                  <div>023-68636622</div>
                </div>
              </div>
              <div class="phone2">
                <div class="dianhua1">
                  <div>客服QQ（24h）</div>
                  <div>4006808811</div>
                </div>
                <div class="dianhua2">
                  <div>公司地址</div>
                  <div>重庆市南岸区南城大道199号正联大厦3楼</div>
                </div>
              </div>
            </div>
            <div class="attention">
              <div class="women">关注我们</div>
              <div class="erweima">
                <div class="fuwu">
                  <img src="../../assets/img/qushangwang.png" />
                  <div>去上网APP微信服务号</div>
                </div>
                <div class="gongzong">
                  <img src="../../assets/img/baquan.jpg" />
                  <div>8圈计费微信公众号</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "palm1",
  data() {
    return {};
  },
  methods: {
    //跳转首页
    palm0() {
      this.$router.push("/palm0");
    },
    //跳转产品介绍
    palm1() {
      this.$router.push("/palm1");
    },
    //跳转功能介绍
    palm2() {
      this.$router.push("/palm2");
    },
    //跳转下载
    palm3() {
      this.$router.push("/palm3");
    },
    //跳转备案
    beian() {
      window.open(
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802002169"
      );
    },
    //跳转营业执照
    zhizhao(){
      let routeData = this.$router.resolve('/yinye');
      window.open(routeData.href, '_blank');
    }
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
  		var clientWidth = document.body.clientWidth;
  		var rate = clientWidth / 1920;
  		*/
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1200px;
  height: 80px !important;
  box-sizing: border-box;
  padding: 16px 0 0;
  margin: 0 auto;
  .maintitle {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    > img {
      width: 148px;
      height: 48px;
      margin-right: 50px;
      &:hover {
        cursor: pointer;
      }
    }
    > div {
      width: 72px;
      height: 27px;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 27px;
      color: #626778;
      margin-left: 80px;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      opacity: 1;
      position: relative;
      color: #0e6eff;
      &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 4px;
        background: #0e6eff;
        opacity: 1;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
  }
}
.el-main {
  width: 100%;
  min-width: 1200px;
  padding: 0;
  margin: 0 auto;
  .palmmain {
    margin: 0 auto;
    height: 640px;
    background-image: url(../../assets/img/palm1back.png);
    background-size: cover;
    /* 将背景图片等比缩放填满整个容器 */
    background-repeat: no-repeat;
    background-position: center;
  }
  .palmtal {
    height: 600px;
    box-sizing: border-box;
    padding-top: 80px;
    .talinfo {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      > img {
        margin-left: 160px;
      }
      .left {
        width: 520px;
        .tal {
          width: 520px;
          height: 162px;
          font-size: 36px;
          font-family: Source Han Sans SC;
          font-weight: 500;
          line-height: 54px;
          color: #2d394b;
          margin-top: 20px;
        }
        .tel {
          width: 520px;
          height: 116px;
          font-size: 24px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          line-height: 40px;
          color: #626778;
          margin-top: 30px;
        }
      }
    }
  }
  .liangdian {
    background: #f4f5f9;
    .ldbor {
      width: 1040px;
      margin: 0 auto;
      padding: 80px 0;
      .ldtitle {
        width: 144px;
        height: 54px;
        font-size: 36px;
        font-family: Source Han Sans SC-Bold, Source Han Sans SC;
        font-weight: bold;
        color: #2d394b;
        margin: 0 auto;
      }
      .ldinfo {
        margin-top: 60px;
        display: flex;
        .val1 {
          width: 240px;
          display: flex;
          flex-direction: column;
          align-items: center;
          > img {
            width: 100px;
            height: 100px;
          }
          .valtal {
            height: 29px;
            font-size: 20px;
            font-family: Source Han Sans SC-Bold, Source Han Sans SC;
            font-weight: bold;
            color: #2d394b;
            margin: 20px 0 12px;
          }
          .valtel {
            font-size: 16px;
            line-height: 24px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
            text-align: center;
          }
        }
        .val2 {
          width: 240px;
          margin: 0 160px;
          display: flex;
          flex-direction: column;
          align-items: center;
          > img {
            width: 100px;
            height: 100px;
          }
          .valtal {
            height: 29px;
            font-size: 20px;
            font-family: Source Han Sans SC-Bold, Source Han Sans SC;
            font-weight: bold;
            color: #2d394b;
            margin: 20px 0 12px;
          }
          .valtel {
            font-size: 16px;
            line-height: 24px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
            text-align: center;
          }
        }
        .val3 {
          width: 240px;
          display: flex;
          flex-direction: column;
          align-items: center;
          > img {
            width: 100px;
            height: 100px;
          }
          .valtal {
            height: 29px;
            font-size: 20px;
            font-family: Source Han Sans SC-Bold, Source Han Sans SC;
            font-weight: bold;
            color: #2d394b;
            margin: 20px 0 12px;
          }
          .valtel {
            font-size: 16px;
            line-height: 24px;
            font-family: Source Han Sans SC-Regular, Source Han Sans SC;
            font-weight: 400;
            color: #626778;
            text-align: center;
          }
        }
      }
    }
  }
}
//底部
.el-footer {
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  padding: 0;
  .fotter {
    height: 338px;
    background: #0b192b;
    padding-top: 51px;
    box-sizing: border-box;
    .fotterdiv {
      width: 1200px;
      height: 225px;
      margin: 0 auto;
      display: flex;
      .yuedong {
        width: 273px;
        height: 225px;
        font-size: 14px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        line-height: 21px;
        color: #626778;
        > img {
          width: 148px;
          height: 48px;
        }
        .text1 {
          margin-top: 37px;
        }
        .text2 {
          margin-top: 14px;
          span:hover{cursor: pointer;color: #ffffff;}
        }
        .text3 {
          margin-top: 14px;
          display: flex;
          justify-items: center;
          img {
            margin-right: 10px;
          }
          .text3tel {
            &:hover {
              cursor: pointer;
              color: #ffffff;
            }
          }
        }
      }
      .bor {
        width: 1px;
        height: 225px;
        margin-left: 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .bor1 {
          flex: 1;
        }
        .bor2 {
          height: 120px;
          border: 1px solid #1b2c43;
        }
      }
      .contactUs {
        width: 475px;
        height: 218px;
        margin-left: 50px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
        }
        .phone,
        .phone2 {
          margin-top: 57px;
          display: flex;
          .dianhua1,
          .dianhua2 {
            div:nth-child(1) {
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #626778;
            }
            div:nth-child(2) {
              height: 22px;
              font-size: 18px;
              font-family: DIN;
              color: #ffffff;
              margin-top: 8px;
            }
          }
          .dianhua1 {
            width: 124px;
          }
          .dianhua2 {
            margin-left: 52px;
          }
        }
        .phone2 {
          margin-top: 30px;
          .dianhua2 div:nth-child(2) {
            font-family: Source Han Sans SC;
            font-size: 16px;
          }
        }
      }
      .attention {
        width: 298px;
        height: 218px;
        margin-left: 67px;
        margin-top: 9px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 18px;
        color: #ffffff;
        .women {
          width: 80px;
          height: 29px;
          margin-left: 10px;
        }
        .erweima {
          display: flex;
          margin-top: 51px;
          .fuwu,
          .gongzong {
            font-size: 14px;
            font-weight: 400;
            color: #626778;
            text-align: center;
            > img {
              width: 110px;
              height: 110px;
            }
            > div {
              margin-top: 8px;
            }
          }
          .gongzong {
            margin-left: 38px;
          }
        }
      }
    }
  }
}
</style>
